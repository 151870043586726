import * as React from 'react';
import Layout from '../../components/layout/layout';
import Seo from '../../components/seo';
import Qa from '../../components/qa/qa';

const QABatteryConnectPage = () => (
  <Layout>
    <Seo title="よくあるお問い合わせ　バッテリーConnect" />
    <Qa
      id="batteryConnect"
      title="バッテリーConnect"
      linkList={[
        {
          label: 'リチウムイオンバッテリーENELORE',
          link: 'http://www.toyota-lf.com/products/detail/enelore/',
        },
      ]}
    ></Qa>
  </Layout>
);

export default QABatteryConnectPage;
